<template>
  <div style="overflow: hidden" class="_container">
    <div
      class="d-flex flex-column"
      style="max-height: 100%; height: 100%; overflow: hidden"
    >
      <div class="pt-6 d-flex justify-center">
        <div style="width: 100%; max-width: 400px">
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            v-model.lazy="cerca"
            color="primary"
            clearable
            rounded
            filled
            placeholder="Cerca"
          />
        </div>
      </div>
      <div style="height: 100%; overflow: hidden">
        <div style="max-height: 100%; overflow-y: scroll; overflow-x: hidden">
          <v-row class="pa-6">
            <v-col
              class="operatore ma-0"
              v-for="operatore in operatori"
              :key="operatore.id"
              cols="12"
              lg="6"
            >
              <v-card
                @click="
                  $router.push({
                    name: 'Operatore',
                    params: { id: operatore.id }
                  })
                "
                elevation="0"
                outlined
                class=""
              >
                <v-card-title
                  class="pb-0 d-flex"
                  style="gap: 16px; flex-wrap: nowrap"
                >
                  <div style="width: 100%">
                    <span style="word-break: keep-all">
                      {{ operatore.nome }} {{ operatore.cognome }}
                    </span>
                    <v-chip small color="success" v-show="operatore.admin">
                      admin
                    </v-chip>
                  </div>
                  <v-spacer />
                  <div
                    class="d-flex"
                    style="
                      min-width: fit-content;
                      width: fit-content;
                      gap: 16px;
                    "
                  >
                    <v-btn
                      @click.stop="openChangePinDialog(operatore.id)"
                      elevation="0"
                      color="primary"
                    >
                      Cambia Pin
                    </v-btn>
                    <v-btn
                      elevation="0"
                      color="error"
                      @click.stop="openDeleteDialog(operatore.id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-card-title>
                <v-card-subtitle style="height: 3em; padding-top: 1em">
                  {{ operatore.badge }}
                </v-card-subtitle>
                <v-divider />
                <v-card-text>
                  <table>
                    <tr>
                      <td class="campo">Nato/a il</td>
                      <td class="valore">
                        {{ formattaData(operatore.dataNascita) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="campo">Sesso</td>
                      <td class="valore">
                        {{ formattaSesso(operatore.sesso) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="campo">Codice Fiscale</td>
                      <td class="valore">
                        {{ operatore.codiceFiscale }}
                      </td>
                    </tr>
                  </table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div style="position: absolute; bottom: 10px; right: 10px">
      <v-btn @click="aggiungi()" color="primary" fab>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <ControllaOperatoreDialog
      :isDeleteDialog="true"
      :admins="true"
      ref="confermaEliminazioneDialog"
    />
    <ChangePinDialog ref="changePinDialog" />
    <v-snackbar v-model="pinNotvalidError" color="error" :timeout="3000">
      <h2 style="text-align: center">PIN errato</h2>
    </v-snackbar>
  </div>
</template>
<script>
import ControllaOperatoreDialog from "@/components/ControllaOperatoreDialog.vue";
import ChangePinDialog from "@/components/ChangePinDialog.vue";
export default {
  components: {
    ControllaOperatoreDialog,
    ChangePinDialog
  },
  data() {
    return {
      cerca: "",
      pinNotvalidError: false
    };
  },
  methods: {
    openChangePinDialog(id) {
      // console.log(id);
      this.$refs.changePinDialog.show(id);
    },
    aggiungi() {
      this.$router.push("/operatori/aggiungi");
    },
    openDeleteDialog(id) {
      this.$refs.confermaEliminazioneDialog.show(this.deleteOperatore, id);
    },
    async deleteOperatore(operatore, pin, toDelete) {
      // console.log(operatore, pin, toDelete);
      try {
        await this.$store.dispatch("operatori/eliminaOperatore", {
          id: toDelete,
          credentials: {
            operatore,
            pin
          }
        });
        this.$refs.confermaEliminazioneDialog.close();
      } catch (error) {
        console.log(error.message);
        if (error.message === "NotAuthorizedError - Pin not valid") {
          this.pinNotvalidError = true;
        }
      }
    }
  },
  computed: {
    operatori() {
      var operatori = this.$store.getters["operatori/operatori"];

      if (this.cerca) {
        const cerca = this.cerca.toUpperCase().split(" ");
        cerca.forEach((c) => {
          operatori = operatori.filter((o) => {
            return (
              o.nome.includes(c) ||
              o.cognome.includes(c) ||
              (o.badge ? o.badge.toUpperCase().includes(c) : false) ||
              (o.codiceFiscale ? o.codiceFiscale.includes(c) : false)
            );
          });
        });
      }

      return operatori;
    }
  },
  mounted() {}
};
</script>
<style scoped>
.operatore {
  cursor: pointer;
  padding: 10px;
}
/* .operatore .v-card {
  border-radius: 30px;
} */
.operatore table {
  width: 100%;
  border-collapse: collapse;
}

.operatore tr:not(:last-child) {
  border-bottom: 1px solid rgba(62, 62, 62, 0.2);
}

.operatore .campo {
  font-weight: bold;
  padding: 8px 0;
  padding-right: 10px;
  /* border-right: 1px solid rgba(0, 0, 0, 0.2); */
}
.operatore .valore {
  padding-left: 10px;
  text-align: right;
  padding: 8px 0;
}
</style>
