<template>
  <div>
    <v-dialog max-width="400px" persistent v-model="visible">
      <v-card>
        <v-card-title>Cambia pin</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column" style="gap: 16px">
            <v-text-field
              label="Vecchio Pin"
              type="number"
              outlined
              hide-details="auto"
              v-model="form.oldPin"
            />
            <v-text-field
              label="Nuovo Pin"
              type="number"
              outlined
              hide-details="auto"
              v-model="form.newPin"
            />
            <v-text-field
              label="Conferma nuovo Pin"
              type="number"
              outlined
              hide-details="auto"
              v-model="form.newPinConfirm"
            />
          </div>
        </v-card-text>
        <v-card-actions class="justify-end pt-0 pa-6">
          <v-btn text @click="close()">Annulla</v-btn>
          <v-btn
            width="50%"
            :disabled="
              !form.oldPin ||
              !form.newPin ||
              !form.newPinConfirm ||
              form.newPin !== form.newPinConfirm
            "
            elevation="0"
            color="primary"
            :loading="loading"
            @click="changePin()"
          >
            Cambia
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="pinNotvalidError" color="error" :timeout="3000">
      <h2 style="text-align: center">PIN errato</h2>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      id: null,
      loading: false,
      pinNotvalidError: false,
      form: {
        oldPin: null,
        newPin: null,
        newPinConfirm: null
      }
    };
  },
  methods: {
    show(id) {
      if (!id) throw new Error("id is required");
      this.id = id;
      this.visible = true;
    },
    close() {
      this.id = null;
      this.form = {
        oldPin: null,
        newPin: null,
        newPinConfirm: null
      };
      this.visible = false;
    },
    async changePin() {
      try {
        this.loading = true;
        const payload = {
          credentials: {
            operatore: this.id,
            pin: this.form.oldPin
          },
          pin: this.form.newPin
        };
        await this.$store.dispatch("operatori/changePin", payload);
        this.close();
      } catch (error) {
        console.log(error);
        if (error.message === "NotAuthorizedError - Pin not valid") {
          this.pinNotvalidError = true;
        }
      }
      this.loading = false;
    }
  }
};
</script>
<style></style>
